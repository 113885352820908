import React from "react";
import {isLoggedIn} from "../../utils/auth";
import Login from "../Login";

export default function PrivateContent({children}) {
    if (!isLoggedIn()) {
        return <Login />;
    }

    return(
        <>{children}</>
    );
}
