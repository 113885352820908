import React from "react";
import { useLazyQuery } from "@apollo/client";
import gql from 'graphql-tag';

export const isLoggedIn = () => {
    const query = gql`
        query {
            customer {
                email
            }
        }`;

    const [customerQuery, {called, error, loading, data}] = useLazyQuery(query, {
        fetchPolicy: 'network-only',
    });

    if (!called) {customerQuery()};

    if (called) {
        if (data) {
            return !!data.customer?.email;
        }

        if (error) {
            return false;
        }
    }
}


