import React from "react";
import useLoginLink from '../../hooks/useLoginLink';

export default function Login() {
    const loginLink = useLoginLink();

    return (
        <div>
            <a href={loginLink}>SSO Login Link</a>
        </div>
    );
}

