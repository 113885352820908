import React from "react";
import PrivateContent from "../components/PrivateContent";

export default function PrivatePage() {
    return (
        <>
            <h1>Private Page</h1>

            <PrivateContent>
                <div>Example private content</div>
            </PrivateContent>
        </>
    )
}
